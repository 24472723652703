<template>
	<div>
		<!-- Header -->
		<ion-list v-if="labelText != null">
			<ion-radio-group>
				<ion-list-header color="dark">
					<ion-label>{{labelText}}</ion-label>
				</ion-list-header>
			</ion-radio-group>
		</ion-list>
		<!-- Name Form -->
		<div v-if="view == 'name'">
			<ion-item color="dark">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="nameInput" type="text" class="text-capitalize" placeholder="Enter full name"></ion-input>
			</ion-item>	
		</div>
		<!-- Username Form -->
		<div v-if="view == 'username'">
			<ion-item color="dark">
				<div tabindex="0"></div>
				<ion-input v-model="usernameInput" type="text" placeholder="Enter username"></ion-input>
			</ion-item>	
		</div>
	</div>
</template>

<script>
import { IonItem, IonInput, IonLabel, IonList, IonRadioGroup, IonListHeader, toastController } from '@ionic/vue';
//import apiClient from '../services/api';
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useStore } from "vuex";
//import { useRouter } from 'vue-router';

export default defineComponent( {
	name: 'RegisterName',
	props: ['view', 'profileType'],
	components: {
		IonItem, IonInput, IonLabel, IonList, IonRadioGroup, IonListHeader 
	},
	setup(props) {
		//const router = useRouter();
		const store = useStore();
		const name = computed(() => store.state.name);
		const username = computed(() => store.state.username);
		const nameInput = ref(null);
		const usernameInput = ref(null);
		const toastMessage = ref(null);
		const labelText = ref(null);

		onMounted(() => {
			// User
			if(props.view == 'name') {
				labelText.value = 'Please enter your first and last name.'
			}
			// Username
			if(props.view == 'username') {
				labelText.value = 'Please create a username.'
			}
		})

		function storeName(val) {
			store.commit('setName', val)
		}

		function storeUsername(val) {
			store.commit('setUsername', val)
		}

		function validateUsernameFormat(val) {
			let re = /^[a-zA-Z0-9_]+$/;
			return re.test(String(val).toLowerCase())
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 3000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

					}
				}]
			})
			return toast.present();
		}

		return {
			name, username, nameInput, usernameInput, storeName, storeUsername, toastMessage, openToast, validateUsernameFormat, labelText
		}
	},
	watch: {
		nameInput: function() {
			if(this.nameInput != null && this.nameInput != '') {
				this.storeName(this.nameInput)
			} else {
				this.storeName(null)
			}
		},
		usernameInput: function() {
			if(this.usernameInput != null && this.usernameInput != '') {
				if(this.validateUsernameFormat(this.usernameInput) === true) {
					this.storeUsername(this.usernameInput)
				} else {
					this.storeUsername(null)
					this.toastMessage = 'Username can only contain letters, numbers and underscores.'
					this.openToast()
				}
			} 
		}
	}
});
</script>

<style scoped>
  ion-list-header ion-label {
    font-weight:bold;
    font-size:18px;
  }
  ion-list {
    padding-top:0px!important;
    padding-bottom:0px!important;
  }
</style>