<template>
  <ion-page>
    <!-- Registration nav -->
    <ion-header id="dark-header">
      <div class="app-max-width">

        <ion-toolbar class="text-center">

          <ion-buttons slot="start">
            <!-- Sign In -->
            <ion-button v-if="btnLeft == 'back-to-sign-in'" @click.prevent="updateRoute('sign-in')">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
            <!-- Sign In -->
            <ion-button v-if="btnLeft == 'back-to-category-list'" @click.prevent="updateView('business-category-list')">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
            <!-- Close -->
            <ion-button v-if="btnLeft == 'close'" @click.prevent="resetForm()">
              <ion-icon :icon="close"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-buttons v-if="showBtnRight === true" slot="end">
            <!-- Next Button -->
            <ion-button v-if="btnRight == 'next'" @click.prevent="next()">
              <ion-icon :icon="chevronForward"></ion-icon>
            </ion-button>
            <!-- Add Button -->
            <ion-button v-if="btnRight == 'add-category'" @click.prevent="addCategory()">
              <ion-icon :icon="add"></ion-icon>
            </ion-button>
          </ion-buttons>

        </ion-toolbar>
      </div>
    </ion-header>

    <!-- Page Content -->
    <ion-content color="dark">
      <div class="app-max-width">

        <!--{{isError}} | {{errors}}-->

        <!-- Step 1 - Profile Type -->
        <div v-if="step === 1" class="padding-right-md">
          <ion-list>
            <ion-radio-group v-model="profileType">
              <ion-list-header color="dark">
                <ion-label>What type of profile would you like to create?</ion-label>
              </ion-list-header>

              <ion-item color="dark">
                <ion-label>User</ion-label>
                <ion-radio slot="start" value="user"></ion-radio>
              </ion-item>

              <ion-item color="dark">
                <ion-label>Artist</ion-label>
                <ion-radio slot="start" value="artist" ></ion-radio>
              </ion-item>

              <ion-item color="dark">
                <ion-label>Business</ion-label>
                <ion-radio slot="start" value="business"></ion-radio>
              </ion-item>

              <ion-item color="dark">
                <ion-label>Organization</ion-label>
                <ion-radio slot="start" value="organization"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list> 
        </div>
        <!-- Step 2 - Name -->
        <div v-if="step === 2">
          <RegisterName view="name" :profile-type="profileType" />
        </div>
        <!-- Step 3 - Username -->
        <div v-if="step === 3">
          <RegisterName view="username" :profile-type="profileType" />
        </div>
        <!-- Step 4 - Email -->
        <div v-if="step === 4">
          <RegisterEmail />
        </div>
        <!-- Step 4 - Password -->
        <div v-if="step === 5">
          <RegisterPassword />
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>
<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonList, IonRadioGroup, IonListHeader, IonItem, IonLabel, IonRadio, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import RegisterName from '../components/register/RegisterName.vue'
import RegisterEmail from '../components/register/RegisterEmail.vue'
import RegisterPassword from '../components/register/RegisterPassword.vue'
import { useStore } from "vuex";
import { chevronBack, chevronForward, add, close } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
  name: 'SignUpPage',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonList, IonRadioGroup, IonListHeader, IonItem, IonLabel, IonRadio, RegisterName, RegisterEmail, RegisterPassword
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const name = computed(() => store.state.name);
    const username = computed(() => store.state.username);
    const email = computed(() => store.state.email);
    const password = computed(() => store.state.password);
    const profileType = ref(null);
    const step = ref(1);
    const view = ref(null);
    const btnLeft = ref('back-to-sign-in');
    const btnRight = ref('next');
    const showBtnRight = ref(false);
    const toastMessage = ref(null);
    const timeout = { default: 6000 }
    const isFormValidated = ref(false);
    const isError = ref(false);
    const errors = ref([]);

    function next() {
      // Step when clicked was 2
      if(step.value === 2) {
        step.value = 3
      }
      if(step.value === 3) {
        if(username.value) {
          isUsernameTaken()
        }
      }
      if(step.value === 4) {
        if(email.value) {
          isEmailTaken()
        }
      }
      if(step.value === 5) {
        validateForm()
      }
    }

    // Used to go back to sign in
    function updateRoute(val) {
      router.push(val)
    }

    function checkName() {
        if(name.value === null || name.value == '') {
          showBtnRight.value = false
        } else {
          showBtnRight.value = true
        }
    }

    function checkUsername() {
        if(username.value === null || username.value == '') {
          showBtnRight.value = false
        } else {
          showBtnRight.value = true

        }
    }

    function checkEmail() {
        if(email.value === null || email.value == '') {
          showBtnRight.value = false
        } else {
          showBtnRight.value = true
        }
    }

    function checkPassword() {
        if(password.value === null || password.value == '') {
          showBtnRight.value = false
        } else {
          showBtnRight.value = true
        }
    }

    function isUsernameTaken() {
      presentLoading()
      apiClient.get('/api/is-username-taken/' +username.value)
      .then(response => {
        if(response.data.message == 'success') {
          step.value = 4
        }
        if(response.data.message == 'fail-username-exists') {
          toastMessage.value = 'That username is already taken.'
          openToast()
        }
      })
    }

    function isEmailTaken() {
      presentLoading()
      apiClient.get('/api/is-email-taken/' +email.value)
      .then(response => {
        if(response.data.message == 'success') {
          step.value = 5
        }
        if(response.data.message == 'fail-email-exists') {
          toastMessage.value = 'That email is already taken.'
          openToast()
        }
      })
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
        {
          icon: 'close',
          role: 'cancel',
          handler: () => {

          }
        }]
      })
      return toast.present();
    }

    function resetForm() {
      profileType.value = null
      step.value = 1
      view.value = null
      store.commit('setName', null)
      store.commit('setUsername', null)
      store.commit('setEmail', null)
      btnLeft.value = 'back-to-sign-in'
      btnRight.value = 'next'
      showBtnRight.value = false
      isFormValidated.value = false
      isError.value = false
      errors.value = [] 
    }

    function validateForm() {
      // Profile Type
      let profileTypeValidated = false
      if(profileType.value != null && profileType.value != '') {
        profileTypeValidated = true
      } 
      // Name
      let nameValidated = false
      if(name.value != null && name.value != '') {
        nameValidated = true
      }
      // Username
      let usernameValidated = true
      if(username.value != null && username.value != '') {
        usernameValidated = true
      }
      // Email
      let emailValidated = false
      if(email.value != null && email.value != '') {
        emailValidated = true
      }
      // Password
      let passwordValidated = false
      if(password.value != null && password.value != '') {
        passwordValidated = true
      }

      if(profileTypeValidated === true && nameValidated === true && usernameValidated === true && emailValidated === true && passwordValidated === true) {
        isFormValidated.value = true
      } else {
        isFormValidated.value = false
      }
    }

    function signUp() {
      presentLoading()
      // eslint-disable-next-line no-unused-vars
      apiClient.get('/sanctum/csrf-cookie').then(response => {
        // eslint-disable-next-line no-unused-vars
        apiClient.post('/register', {
          name: name.value,
          username: username.value,
          email: email.value,
          profileType: profileType.value,
          password: password.value,
          confirmPassword: password.value
        }).then(response => {
          console.log(response) 
          if(response.status === 201) {
            // eslint-disable-next-line no-unused-vars
            apiClient.post('/api/login', {
              email: email.value,
              password: password.value
            }).then(response => {
              // Successful login
              if(response.status === 201) {
                // Set token in storage
                localStorage.setItem('authToken', response.data.token);
                // Set is Auth in storage
                localStorage.setItem('isAuth', true);
                // Set Auth User in storage
                localStorage.setItem('authUser', JSON.stringify(response.data.user));
                // Set storage var
                localStorage.setItem('activeUser', false)
                // Reset vars
                resetForm()
                // Show message
                openToast()
                window.location = '/home'
              } else {
                isError.value = true
              }
            }).catch(error => {
              console.log(error)  
              // credentials didn't match
              if(error.response.status === 422) {
                isError.value = true
                errors.value = error.response.data.errors
              }
              if(error.response.status === 404) {
                isError.value = true
                errors.value = error.response.data.errors
              }
            });           
          } else {
            isError.value = true
          }
        }).catch(error => {
          console.log(error)  
          // credentials didn't match
          if(error.response.status === 422) {
            isError.value = true
            errors.value = error.response.data.errors
          }
          if(error.response.status === 404) {
            isError.value = true
            errors.value = error.response.data.errors
          }
        }); 
      });
    }

    function doErrors() {
      if(errors.value.length > 0) {
        for(var i=0;i<errors.value.length;i++) {
          if(errors.value[i] == 'username') {
            toastMessage.value = errors.value[i]
            openToast()
          }
        }
      } 
    }

    return {
      router, store, profileType, step, view, resetForm, name, username, chevronBack, chevronForward, add, close, btnLeft, btnRight, showBtnRight, next, updateRoute, checkName, checkUsername, email, checkEmail, password, checkPassword, isFormValidated, openToast, isError, errors, signUp, doErrors
    }
  },
  watch: {
    step: function() {
      if(this.step > 1) {
        this.btnLeft = 'close'
      }
      if(this.step === 2) {
        this.checkName()
      }
      if(this.step === 3) {
        this.checkUsername()
      }
      if(this.step === 4) {
        this.checkEmail()
      }
      if(this.step === 5) {
        this.checkPassword()
      }
    },
    profileType: function() {
      //
      if(this.profileType != null) {
        this.step = 2
      }
    },
    name: function() {
      this.checkName()
    },
    username: function() {
      this.checkUsername()
    },
    email: function() {
      this.checkEmail()
    },
    password: function() {
      this.checkPassword()
    },
    isFormValidated: function() {
      if(this.isFormValidated === true) {
        this.signUp()
      } else {
        this.toastMessage = 'Something is missing'
        this.openToast()
      }
    },
    isError: function() {
      if(this.isError === true) {
        if(this.errors.length > 0) {
          this.doErrors()
        } else {
          this.toastMessage = 'Something went wrong.'
          this.openToast()   
        }
      } else {
        this.errors = []
      }
    }
  }
});
</script>

<style scoped>
  ion-list-header ion-label {
    font-weight:bold;
    font-size:18px;
  }
  ion-list {
    padding-top:0px!important;
    padding-bottom:0px!important;
  }
</style>