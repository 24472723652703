<template>
	<div>
		<ion-list>
			<ion-radio-group>
				<ion-list-header color="dark">
					<ion-label>Please enter your password.</ion-label>
				</ion-list-header>
			</ion-radio-group>
		</ion-list>
		<!-- Password -->
		<div>
			<ion-item color="dark">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="passwordInput" type="password" placeholder="Password"></ion-input>
			</ion-item>	
		</div>
		<!-- Confirm Password -->
		<div>
			<ion-item color="dark">
				<div tabindex="0"></div>
				<ion-input v-model="confirmPasswordInput" type="password" placeholder="Confirm Password"></ion-input>
			</ion-item>	
		</div>
	</div>
</template>

<script>
import { IonItem, IonInput, IonLabel, IonList, IonRadioGroup, IonListHeader, toastController } from '@ionic/vue';
//import apiClient from '../services/api';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from "vuex";
//import { useRouter } from 'vue-router';

export default defineComponent( {
	name: 'RegisterPassword',
	props: [],
	components: {
		IonItem, IonInput, IonLabel, IonList, IonRadioGroup, IonListHeader 
	},
	setup() {
		//const router = useRouter();
		const store = useStore();
		const password = computed(() => store.state.password);
		const passwordInput = ref(null);
		const confirmPasswordInput = ref(null);
		const passwordFieldsMatch = ref(null);
		const toastMessage = ref(null);

		function storePassword(val) {
			store.commit('setPassword', val)
		}

		function doPasswordFieldsMatch(a, b) {
			if(a == b) {
				passwordFieldsMatch.value = true
			} else {
				passwordFieldsMatch.value = false
				storePassword(null)
				toastMessage.value = 'Both password fields must match.'
				openToast()
			}
		}

		function validatePassword() {
			doPasswordFieldsMatch(passwordInput.value, confirmPasswordInput.value)
		}

		function doPasswordLength(val) {
			// Length is valid
			if(val.length >= 8 && val.length <= 255) {
				return 1
			} 
			// Length is not valid
			else {
				return 0
			}
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 1000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

					}
				}]
			})
			return toast.present();
		}

		return {
			password, passwordInput, confirmPasswordInput, storePassword, passwordFieldsMatch, doPasswordFieldsMatch, validatePassword, openToast, doPasswordLength, toastMessage
		}
	},
	watch: {
		passwordInput: function() {
			if(this.passwordInput != null && this.passwordInput != '') {
				if(this.doPasswordLength(this.passwordInput) === 1) {
					this.validatePassword()
				}
				else {
					this.toastMessage = 'Password must be at least 8 characters.'
					this.openToast()				
				}
			} else {
				this.storePassword(null)
			}
		},
		confirmPasswordInput: function() {
			if(this.confirmPasswordInput != null && this.confirmPasswordInput != '') {
				if(this.doPasswordLength(this.confirmPasswordInput) === 1) {
					this.validatePassword()
				}
				else {
					this.toastMessage = 'Password must be at least 8 characters.'
					this.openToast()				
				}
			} else {
				this.storePassword(null)
			}
		}, 
		passwordFieldsMatch: function() {
			if(this.passwordFieldsMatch === true) {
				this.storePassword(this.passwordInput)
			} else {
				this.storePassword(null)
			}
		}
	}
});
</script>

<style scoped>
  ion-list-header ion-label {
    font-weight:bold;
    font-size:18px;
  }
  ion-list {
    padding-top:0px!important;
    padding-bottom:0px!important;
  }
</style>