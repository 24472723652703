<template>
	<div>
		<ion-list>
			<ion-radio-group>
				<ion-list-header color="dark">
					<ion-label>Please enter your email address.</ion-label>
				</ion-list-header>
			</ion-radio-group>
		</ion-list>
		<!-- Email -->
		<div>
			<ion-item color="dark">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="emailInput" type="email" placeholder="Enter Email"></ion-input>
			</ion-item>	
		</div>
		<!-- Confirm Email -->
		<div>
			<ion-item color="dark">
				<div tabindex="0"></div>
				<ion-input v-model="confirmEmailInput" type="email" placeholder="Confirm Email"></ion-input>
			</ion-item>	
		</div>
	</div>
</template>

<script>
import { IonItem, IonInput, IonLabel, IonList, IonRadioGroup, IonListHeader, toastController } from '@ionic/vue';
//import apiClient from '../services/api';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from "vuex";
//import { useRouter } from 'vue-router';

export default defineComponent( {
	name: 'RegisterEmail',
	props: [],
	components: {
		IonItem, IonInput, IonLabel, IonList, IonRadioGroup, IonListHeader 
	},
	setup() {
		//const router = useRouter();
		const store = useStore();
		const email = computed(() => store.state.email);
		const emailInput = ref(null);
		const confirmEmailInput = ref(null);
		const emailFieldsMatch = ref(null);
		const toastMessage = ref(null);

		function storeEmail() {
			store.commit('setEmail', emailInput.value)
		}

		function doEmailFieldsMatch(a, b) {
			console.log(3)
			if(a == b) {
				emailFieldsMatch.value = true
			} else {
				emailFieldsMatch.value = false
				store.commit('setEmail', null)
				toastMessage.value = 'Both email fields must match.'
				openToast()
			}
		}

		function validateEmailFormat(val) {
			let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(String(val).toLowerCase())
		}

		function validateEmail() {
			// validate email format
			if(validateEmailFormat(emailInput.value) === true) {
				if(validateEmailFormat(confirmEmailInput.value) === true) {
					// both email fields match
					doEmailFieldsMatch(emailInput.value, confirmEmailInput.value)					
				}				
			}
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 1500,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {

					}
				}]
			})
			return toast.present();
		}

		return {
			email, emailInput, confirmEmailInput, storeEmail, validateEmail, emailFieldsMatch
		}
	},
	watch: {
		emailInput: function() {
			if(this.emailInput != null && this.emailInput != '') {
				this.validateEmail()
			} 
		},
		confirmEmailInput: function() {
			if(this.confirmEmailInput != null && this.confirmEmailInput != '') {
				this.validateEmail()
			} 
		}, 
		emailFieldsMatch: function() {
			if(this.emailFieldsMatch === true) {
				this.storeEmail()
			}
		}
	}
});
</script>

<style scoped>
  ion-list-header ion-label {
    font-weight:bold;
    font-size:18px;
  }
  ion-list {
    padding-top:0px!important;
    padding-bottom:0px!important;
  }
</style>